import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () =>
    import("../views/Home.vue"),
  },
  {
    path: "/about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/About.vue"),
  },
  {
    path: "/projects",
    component: () =>
      import("../views/Projects.vue"),
  },
  {
    path: "/art",
    component: () =>
      import("../views/Art.vue"),
  },
  {
    path: "/projects/kawasaki",
    component: () =>
      import("../views/Kawasaki.vue"),
  },
  {
    path: "/projects/knacktive",
    component: () =>
      import("../views/Knacktive.vue"),
  },
  {
    path: "/projects/strong",
    component: () =>
      import("../views/Strong.vue"),
  },
  {
    path: "/projects/csiscontest",
    component: () =>
      import("../views/CsisContest.vue"),
  },
  {
    path: "/projects/partiyorkies",
    component: () =>
      import("../views/PartiYorkies.vue"),
  },
  {
    path: "/projects/iss",
    component: () =>
      import("../views/Iss.vue"),
  },
  {
    path: "/projects/sigtau",
    component: () =>
      import("../views/SigTau.vue"),
  },
  {
    path: "/projects/amamusements",
    component: () =>
      import("../views/AmAmusements.vue"),
  },
  {
    path: "/art/glitchart",
    component: () =>
      import("../views/GlitchArt.vue"),
  },
  {
    path: "/art/generativeart",
    component: () =>
      import("../views/GenerativeArt.vue"),
  },
  {
    path: "/art/illustration",
    component: () =>
      import("../views/Illustration.vue"),
  },
  {
    path: "/art/graphicdesign",
    component: () =>
      import("../views/GraphicDesign.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { left: 0, top: 0 }
  }  
});

export default router;
